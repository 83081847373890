import * as React from "react";
import { ReactNode } from "react";

import { ButtonProps } from "@source-web/button";
import { Heading } from "@source-web/heading";
import { Paragraph } from "@source-web/paragraph";

import { CustomQuantitySelector } from "../../../../components";
import { PrimaryButton } from "../../../../components/PrimaryButton";
import { useCart } from "../../../../context/cart/useCart";
import * as Styled from "./QuantitySelector.styles";

interface QuantitySelectorProps {
  productId: string;
  itemPrice: string | number;
  isMobile: boolean;
  showSelector: boolean;
  clickedMoreDetails: boolean;
  quantitySelectionChildren: ReactNode;
  buttonArgs: ButtonProps;
  handleCtaClick(value: number): Promise<void>;
  handleClick?(value: number): void;
}

export const QuantitySelector: React.FC<QuantitySelectorProps> = ({
  handleClick = () => {},
  handleCtaClick = () => {},
  productId,
  itemPrice,
  isMobile = false,
  showSelector = true,
  clickedMoreDetails = false,
  quantitySelectionChildren,
  buttonArgs
}) => {
  const [value, setValue] = React.useState(1);
  const [isInvalid, setIsInvalid] = React.useState(false);
  const cart = useCart();

  const addedToCart =
    cart?.cartState?.items.find((item) => {
      return item.id === productId;
    }) !== undefined;

  function handleOnChange(value: number) {
    if (isInvalid) setIsInvalid(false);
    setValue(value);
    handleClick && handleClick(value);
  }

  if (addedToCart) {
    buttonArgs.text = "Added to Basket";
  }

  return (
    <Styled.QuantitySelectionWrapper>
      {itemPrice ? (
        !isMobile ? (
          <Paragraph>
            Starting at <br />
            <Styled.PriceContainer>
              <>
                <Heading level={3} weight={3} text={`${itemPrice}`} />
                {quantitySelectionChildren && quantitySelectionChildren}
              </>
            </Styled.PriceContainer>
          </Paragraph>
        ) : (
          <div style={{ marginLeft: "20%" }}>
            <Paragraph>
              Starting at <br />
              <Styled.PriceContainer>
                <>
                  <Heading level={3} weight={3} text={`${itemPrice}`} />
                  {quantitySelectionChildren && quantitySelectionChildren}
                </>
              </Styled.PriceContainer>
            </Paragraph>
          </div>
        )
      ) : null}
      {!isMobile ? (
        <Styled.QuantitySelection>
          {showSelector ? (
            <CustomQuantitySelector
              min={1}
              value={value}
              onInvalid={() => setIsInvalid(true)}
              onChange={handleOnChange}
            />
          ) : null}
          {buttonArgs?.text ? (
            <PrimaryButton
              state={isInvalid || addedToCart ? "success" : undefined}
              onClick={() => {
                if (!addedToCart) {
                  handleCtaClick(value);
                }
              }}
              data-testid="addToCart"
              {...buttonArgs}
            />
          ) : null}
        </Styled.QuantitySelection>
      ) : (
        <Styled.QuantitySelection>
          {showSelector ? (
            <div style={clickedMoreDetails ? { textAlign: "center" } : {}}>
              <CustomQuantitySelector
                min={1}
                value={value}
                onInvalid={() => setIsInvalid(true)}
                onChange={handleOnChange}
              />
            </div>
          ) : null}
          {buttonArgs?.text ? (
            <Styled.ButtonMobile>
              <PrimaryButton
                {...buttonArgs}
                width="full"
                state={isInvalid || addedToCart ? "success" : undefined}
                onClick={() => {
                  if (!addedToCart) {
                    handleCtaClick(value);
                  }
                }}
              />
            </Styled.ButtonMobile>
          ) : null}
        </Styled.QuantitySelection>
      )}
    </Styled.QuantitySelectionWrapper>
  );
};
