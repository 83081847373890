import { ExplainerTileThemeType } from "./ExplainerTile.theme.types";

const explainerTileLocal = (theme: any): ExplainerTileThemeType => {
  return {
    background: theme.color.monochrome1.default,
    iconBackground: "#454545"
  };
};

export default explainerTileLocal;
