import { paramCase } from "param-case";
import * as React from "react";
import { ComponentPropsWithoutRef, useState } from "react";

import { Heading } from "@source-web/heading";
import { Icon } from "@source-web/icon";
import { LinkWithIcon } from "@source-web/link-with-icon";
import { Modal } from "@source-web/modal";
import { Span } from "@source-web/span";

import { IconSnippet } from "@vfuk/core-icon-snippet";

import {
  trackLearnMoreCybershopClick,
  trackRequestCallbackCybershopClick,
  trackSubmitCallbackCybershopClick
} from "../../lib";
import { sendFormToEloqua } from "../../lib/services/supportFormService";
import { RequestCallbackType } from "../../pageTemplates/explore/queries/queries.types";
import { MessagePopupProps } from "../../pageTemplates/explore/types";
import { ContentfulRichText } from "../ContentfulRichText";
import MessagePopup from "../MessagePopup";
import { PrimaryButton } from "../PrimaryButton";
import RequestCallback from "../RequestCallback/RequestCallback";
import * as Styled from "./styles/ExplainerTile.style";

export interface ExplainerTileProps extends ComponentPropsWithoutRef<"div"> {
  /** Text displayed at the top */
  headingText?: string;
  /** Large icon displayed on the left */
  mainIcon?: string;
  /** Alternative Text for Large icon */
  iconAltText?: string;
  /** Main text displayed under the heading */
  summaryText: {
    raw: string;
  };
  /** First text in the list */
  listData?: Array<{ text: string; icon?: string }>;
  /** Text displayed for the link  */
  linkText?: string;
  /** icon displayed for the link */
  linkIcon?: string;
  /** the link */
  link?: string;
  /** the unit selection for adding items into the cart */
  children?: any;
  /** flag for toggling unit selector on/off */
  showUnitSelection?: boolean;
  isMobile?: boolean;
  handleClickMoreDetails?: (event: boolean, productId: string) => void;
  productId?: string;
  requestCallbackData: RequestCallbackType;
  requestCallBackButton: string;
  formattedLocale: string;
  modalSuccessfull?: MessagePopupProps | undefined;
  modalError?: MessagePopupProps | undefined;
}

const RequestCallbackModal = ({
  isOpen,
  setIsOpen,
  requestCallbackData,
  productId,
  headingText,
  formattedLocale,
  modalSuccessfull,
  modalError
}: {
  isOpen: boolean;
  setIsOpen: (arg: boolean) => void;
  requestCallbackData: RequestCallbackType;
  productId?: string;
  headingText?: string;
  formattedLocale: string;
  modalSuccessfull: MessagePopupProps;
  modalError: MessagePopupProps;
}) => {
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [isError, setIsError] = useState(false);

  const timeOut = 2000;

  const handleSubmit = async (values: any) => {
    try {
      await sendFormToEloqua(values);
      headingText &&
        trackSubmitCallbackCybershopClick({
          page_locale: formattedLocale,
          journey_type: headingText
        });
      setTimeout(() => {
        setIsOpen(false);
        setIsSuccessful(true);
      }, timeOut);
    } catch (error) {
      setTimeout(() => {
        setIsOpen(false);
        setIsError(true);
      }, timeOut);
      console.error(error);
    }
  };

  return (
    <>
      {isSuccessful && !isError && (
        <MessagePopup
          message={modalSuccessfull.message}
          button={modalSuccessfull.button}
          type={modalSuccessfull.type}
          icon={modalSuccessfull.icon}
          formattedLocale={formattedLocale}
          openState={isSuccessful}
          setOpenState={setIsSuccessful}
          onClose={() => setIsSuccessful(false)}
        />
      )}
      {!isSuccessful && isError && (
        <MessagePopup
          message={modalError.message}
          button={modalError.button}
          type={modalError.type}
          icon={modalError.icon}
          formattedLocale={formattedLocale}
          openState={isError}
          setOpenState={setIsError}
          onClose={() => setIsError(false)}
        />
      )}
      {!isSuccessful && !isError && (
        <Modal
          overlayBlur
          srName="request-callback"
          appearance="primary"
          isOpen={isOpen}
          size={3}
          animate
          isClosable
          onCloseCb={() => setIsOpen(false)}
        >
          <RequestCallback
            data={requestCallbackData}
            onSubmit={handleSubmit}
            productId={productId}
          />
        </Modal>
      )}
    </>
  );
};

const ExplainerTile = ({
  formattedLocale,
  headingText,
  mainIcon,
  iconAltText,
  summaryText,
  listData,
  linkText,
  linkIcon,
  link,
  showUnitSelection = false,
  isMobile = false,
  children = null,
  productId,
  requestCallbackData,
  handleClickMoreDetails,
  requestCallBackButton,
  modalSuccessfull,
  modalError,
  ...otherProps
}: ExplainerTileProps) => {
  const [clickedMoreDetails, setClickedMoreDetails] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const tileIcon = isMobile ? (
    <Styled.IconWrapperMobile>
      <img
        style={{ width: "16px", height: "16px" }}
        src={mainIcon}
        alt={iconAltText}
      />
    </Styled.IconWrapperMobile>
  ) : (
    <Styled.IconWrapper>
      <img src={mainIcon} alt={iconAltText} />
    </Styled.IconWrapper>
  );

  const ParagraphText = summaryText && (
    <ContentfulRichText text={summaryText.raw} />
  );
  const HeadingText = isMobile ? (
    <Heading size={3} text={headingText} weight={2} />
  ) : (
    <Heading size={3} text={headingText} weight={3} />
  );

  const trackLearnMore = () => {
    headingText &&
      trackLearnMoreCybershopClick({
        page_locale: formattedLocale,
        journey_type: headingText
      });
  };

  const DesktopLinkComponent = () =>
    link && linkText ? (
      <Styled.LinkWrapper
        href={link}
        target="_blank"
        onClick={() => trackLearnMore()}
      >
        <Span weight={3}>{linkText}</Span>
        <Icon size={1} name={linkIcon || "pop-out"} appearance="secondary" />
      </Styled.LinkWrapper>
    ) : null;

  const MobileLinkComponent = () =>
    link && linkText ? (
      <Styled.MobileLinkWrapper
        href={link}
        target="_blank"
        onClick={() => trackLearnMore()}
      >
        <Span weight={3}>{linkText}</Span>
        <Icon size={1} name={linkIcon || "pop-out"} appearance="secondary" />
      </Styled.MobileLinkWrapper>
    ) : null;

  const iconList = (
    <Styled.ListWrapper>
      {listData?.map(({ text, icon }) => (
        <Styled.ListItemWrapper key={icon}>
          <IconSnippet
            text={text}
            icon={{
              name: icon
            }}
          />
        </Styled.ListItemWrapper>
      ))}
    </Styled.ListWrapper>
  );

  return (
    <Styled.ExplainerTileWrapper
      {...otherProps}
      id={paramCase(headingText as string)}
    >
      <Styled.DesktopLayout>
        <Styled.ContentWrapper>
          {tileIcon}

          <Styled.DesktopCentralWrapper>
            {HeadingText}
            <Styled.TextWrapper>
              <Styled.TextSpaceWrapper>
                {ParagraphText}
                <DesktopLinkComponent />
              </Styled.TextSpaceWrapper>
              {iconList}
            </Styled.TextWrapper>
          </Styled.DesktopCentralWrapper>
        </Styled.ContentWrapper>
        {showUnitSelection && React.isValidElement(children) ? children : null}

        <Styled.RequestCallbackContainer>
          <PrimaryButton
            text={requestCallBackButton}
            onClick={() => {
              setIsOpen(true);
              headingText &&
                trackRequestCallbackCybershopClick({
                  page_locale: formattedLocale,
                  journey_type: headingText
                });
            }}
          />
        </Styled.RequestCallbackContainer>
        <RequestCallbackModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          requestCallbackData={requestCallbackData}
          productId={productId}
          headingText={headingText}
          formattedLocale={formattedLocale}
          modalSuccessfull={modalSuccessfull as MessagePopupProps}
          modalError={modalError as MessagePopupProps}
        />
      </Styled.DesktopLayout>

      <Styled.MobileLayout>
        <div>
          <Styled.TextWrapper>
            <Styled.HeadingWrapper>
              {tileIcon}
              {HeadingText}
            </Styled.HeadingWrapper>
            {isMobile && !clickedMoreDetails ? (
              <Styled.MoreDetailsWrapper
                onClick={() => {
                  handleClickMoreDetails!(!clickedMoreDetails, productId!);
                  setClickedMoreDetails(!clickedMoreDetails);
                }}
              >
                <LinkWithIcon
                  appearance="secondary"
                  text="More details"
                  icon={{
                    name: "chevron-right",
                    justify: "right"
                  }}
                  href="#"
                  size={2}
                />
              </Styled.MoreDetailsWrapper>
            ) : null}
            {clickedMoreDetails || !isMobile ? ParagraphText : null}
          </Styled.TextWrapper>
          {clickedMoreDetails || !isMobile ? (
            <>
              {iconList}
              <MobileLinkComponent />
            </>
          ) : null}
        </div>
        {showUnitSelection && React.isValidElement(children) ? children : null}

        <Styled.RequestCallbackContainer>
          <PrimaryButton
            text={requestCallBackButton}
            onClick={() => {
              setIsOpen(true);
            }}
          />
        </Styled.RequestCallbackContainer>
      </Styled.MobileLayout>
    </Styled.ExplainerTileWrapper>
  );
};

export default ExplainerTile;
