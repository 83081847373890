import * as Yup from "yup";

import { errorMessages } from "../../lib/utilities/constants";

export const signInFormSchema = Yup.object().shape({
  firstName: Yup.string().required(errorMessages.noEmptyFieldAllowed),
  lastName: Yup.string().required(errorMessages.noEmptyFieldAllowed),
  emailAddress: Yup.string().required(errorMessages.noEmptyFieldAllowed),
  mobilePhone: Yup.string().required(errorMessages.noEmptyFieldAllowed),
  company: Yup.string().required(errorMessages.noEmptyFieldAllowed),
  postcode: Yup.string().required(errorMessages.noEmptyFieldAllowed),
  businessSize: Yup.string().notOneOf(
    ["Please select"],
    errorMessages.noEmptyFieldAllowed
  ),
  jobFunction: Yup.string().notOneOf(
    ["Please select"],
    errorMessages.noEmptyFieldAllowed
  ),
  natureOfEnquiry: Yup.string().notOneOf(
    ["Please select"],
    errorMessages.noEmptyFieldAllowed
  ),
  existingCustomer: Yup.string().notOneOf(
    ["Please select"],
    errorMessages.noEmptyFieldAllowed
  )
});
