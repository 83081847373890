import styled, { css } from "styled-components";

export const HeadingContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    justify-content: center;
    padding-bottom: ${theme.spacing.fixed[4]}px;
  `
);

export const MainContainer = styled.div(
  () => css`
    label {
      font-size: 1em;
    }
  `
);

export const ParagraphContainer = styled.div(
  () => css`
    margin: 1.25em;
    p {
      font-size: 1em;
    }
    a {
      text-underline-offset: 0.2em;
    }
  `
);

export const CheckboxContainer = styled.div(
  () => css`
    margin: 1.25em;
  `
);
