import { graphql, navigate } from "gatsby";
import * as React from "react";
import { useState } from "react";
import { ThemeProvider, useTheme } from "styled-components";

import { Heading } from "@source-web/heading";
import { LinkWithIcon } from "@source-web/link-with-icon";
import { useBreakpoints } from "@source-web/match-media";
import { Paragraph } from "@source-web/paragraph";
import { Tabs } from "@source-web/tabs";

import { Tab } from "@vfuk/core-navigational-tabs";

import ExplainerTile from "../../components/ExplainerTile";
import PageHeading from "../../components/PageHeading";
import { GetPageHeadingType } from "../../components/PageHeading/queries/queries.types";
import { useCart } from "../../context/cart/useCart";
import { priceFormatter, useEventSource } from "../../lib";
import { PageContext } from "../../types";
import { ServiceTileListSkeleton } from "../manage/components/ServiceList/ServiceTileListSkeleton";
import { TabsContainer } from "../servicesPage/styles/ServicesPage.styles";
import { QuantitySelector } from "./components/QuantitySelector";
import {
  ExplainerTileDataType,
  getAllServiceCategory,
  getExplainerTileDataType,
  getRequestCallbackDataType,
  getServiceCategory
} from "./queries/queries.types";
import * as Styled from "./styles/ExplorePage.styles";
import { MarketplaceProduct, MessagePopupProps } from "./types";

export const query = graphql`
  query GetExplorePageData($locale: String) {
    contentfulPageHeading(
      pageName: { eq: "explore" }
      node_locale: { eq: $locale }
    ) {
      heading
      headingBackgroundImageDark {
        gatsbyImageData(height: 450, quality: 95)
      }
      headingBackgroundImageLight {
        gatsbyImageData(height: 450, quality: 95)
      }
      headingBackgroundMobileDark {
        gatsbyImageData(height: 400, quality: 95)
      }
      headingBackgroundMobileLight {
        gatsbyImageData(height: 400, quality: 95)
      }
      headingSubtext
      messageBodyModalOne
      messageIconModalOne
      messageTypeModalOne
      successfullyButton {
        href
        buttonText
        appearance
        openInNewTab
      }
      messageBodyModalTwo
      messageIconModalTwo
      messageTypeModalTwo
      errorButton {
        href
        buttonText
        appearance
        openInNewTab
      }
    }
    contentfulContactInformation(
      pageName: { eq: "explore" }
      node_locale: { eq: $locale }
    ) {
      actionText
      body {
        raw
      }
      heading
      usesButton
    }
    allContentfulExplainerTile(
      filter: { node_locale: { eq: $locale }, isSelfServe: { eq: true } }
      sort: { fields: order }
    ) {
      edges {
        node {
          serviceCategory {
            categoryId
          }
          summaryText {
            raw
          }
          iconAltText
          link
          mainIcon {
            file {
              url
            }
          }
          mainIconLight {
            file {
              url
            }
          }
          headingText
          isSelfServe
          productId
          linkIcon
          linkText
          requestCallBackButton
          childrenContentfulExplainerTileListDataJsonNode {
            text
            icon
          }
        }
      }
    }
    allContentfulServiceCategory(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          order
          categoryId
          categoryName
          heading
          description
        }
      }
    }
    allContentfulRequestCallback(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          title
          firstName
          firstNamePlaceholder
          lastName
          lastNamePlaceholder
          yourEmailAddress
          yourEmailAddressPlaceholder
          contactNumber
          contactNumberPlaceholder
          companyName
          companyNamePlaceholder
          postcode
          postcodePlaceholder
          childrenContentfulRequestCallbackDropdownJsonNode {
            jobFunction {
              text
              value
              disabled
            }
            businessSize {
              text
              value
              disabled
            }
            natureOfEnquiry {
              text
              value
              disabled
              productId
            }
            existingCustomer {
              text
              value
              disabled
            }
          }
          jobFunctionLabel
          businessSizeLabel
          natureOfEnquiryLabel
          existingCustomerLabel
          showExistingCustomerLabel
          customField
          showCustomField
          checkboxText
          privacyPolicy
          privacyPolicyLabel
          privacyPolicyLink
          submitButtonText
        }
      }
    }
  }
`;

export interface ExplorePageType {
  data: GetPageHeadingType &
    getExplainerTileDataType &
    getAllServiceCategory &
    getServiceCategory & {
      contentfulContactInformation: {
        actionText: string;
        body: {
          raw: string;
        };
        heading: string;
        usesButton: boolean;
      };
    } & getRequestCallbackDataType;

  pageContext: PageContext;
}

const ExplorePage = ({
  data,
  pageContext: { formattedLocale, category }
}: ExplorePageType) => {
  const theme = useTheme();
  const cart = useCart();
  const { sm } = useBreakpoints();

  const [clickedMoreDetails, setClickedMoreDetails] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState("0");

  const { allContentfulServiceCategory } = data;
  const tabArray = allContentfulServiceCategory.edges
    .map((item) => ({
      order: item.node.order,
      categoryId: item.node.categoryId,
      categoryName: item.node.categoryName
    }))
    .filter((item) =>
      data.allContentfulExplainerTile.edges.find(
        (e) => e.node.serviceCategory.categoryId === item.categoryId
      )
    )
    .sort((a, b) => a.order - b.order);

  // TODO: remove this logic after the categories are activated for english and portuguese too
  const selectedCategory = formattedLocale === "it" ? category : "protect";

  const {
    isLoading,
    // TODO: commented until ProductOffering API is available
    // isError,
    data: productListData
  } = useEventSource<MarketplaceProduct>("marketplace/product-offering");

  const parsedEnvValue = JSON.parse(
    process.env.GATSBY_ENABLE_SHOPPING_CART || "false"
  );
  const allowShopping = Boolean(parsedEnvValue);

  // TODO: commented until ProductOffering API is available
  // const availableIds = productListData?.map(({ id }) => id) || [];

  // const filterContentById = ({
  //   node: { productId }
  // }: ExplainerTileDataType) => {
  //   return availableIds.includes(String(productId));
  // };

  const getProductPriceForId = (
    productId: string,
    shouldFormat: boolean = false
  ): string | number => {
    const selectedProduct = productListData as MarketplaceProduct;
    if (selectedProduct?.id !== productId) return priceFormatter.format(0);

    const priceValue = selectedProduct.prices?.[0]?.value || "0";

    const price = parseFloat(priceValue);

    return shouldFormat ? priceFormatter.format(price) : price;
  };

  const handleClickMoreDetails = (event: boolean, productId: string) => {
    setClickedMoreDetails(event);
    setSelectedProductId(productId);
  };

  const handleCtaClick = async (
    quantity: number,
    node: ExplainerTileDataType["node"]
  ) => {
    const { mainIcon, mainIconLight, headingText, productId } = node;
    const addedToCart =
      cart?.cartState?.items.find((item) => {
        return item.id === productId;
      }) !== undefined;
    if (!addedToCart) {
      await cart?.addProductToCart({
        id: productId,
        price: getProductPriceForId(productId, false) as number,
        quantity,
        name: headingText,
        lightIcon: mainIconLight,
        darkIcon: mainIcon,
        priceUpfront: "0"
      });
    }
  };

  const selectedServiceCategory = allContentfulServiceCategory.edges.find(
    (e) => e.node.categoryId === selectedCategory
  );
  const tabHeading = selectedServiceCategory?.node?.heading || "";
  const tabDescription = selectedServiceCategory?.node?.description || "";

  const renderItem = (node: ExplainerTileDataType["node"]) => {
    const {
      mainIcon,
      link,
      mainIconLight,
      headingText,
      iconAltText,
      childrenContentfulExplainerTileListDataJsonNode,
      productId,
      summaryText,
      isSelfServe,
      linkIcon,
      linkText,
      requestCallBackButton
    } = node;

    const modalSuccessfull: MessagePopupProps = {
      message: data.contentfulPageHeading.messageBodyModalOne,
      button: data.contentfulPageHeading.successfullyButton,
      type: data.contentfulPageHeading.messageTypeModalOne,
      icon: data.contentfulPageHeading.messageIconModalOne
    };

    const modalError: MessagePopupProps = {
      message: data.contentfulPageHeading.messageBodyModalTwo,
      button: data.contentfulPageHeading.errorButton,
      type: data.contentfulPageHeading.messageTypeModalTwo,
      icon: data.contentfulPageHeading.messageIconModalTwo
    };

    return (
      <Styled.ExplainerTileItemContainer key={headingText}>
        <ExplainerTile
          formattedLocale={formattedLocale}
          key={productId}
          requestCallbackData={data.allContentfulRequestCallback.edges[0]}
          showUnitSelection={allowShopping && isSelfServe}
          productId={productId}
          handleClickMoreDetails={sm ? handleClickMoreDetails : undefined}
          isMobile={sm}
          headingText={headingText}
          summaryText={summaryText}
          mainIcon={
            theme.name !== "WS10" ? mainIcon?.file.url : mainIconLight?.file.url
          }
          listData={childrenContentfulExplainerTileListDataJsonNode}
          iconAltText={iconAltText}
          linkText={linkText || undefined}
          linkIcon={linkIcon || undefined}
          link={link}
          requestCallBackButton={requestCallBackButton}
          modalSuccessfull={modalSuccessfull}
          modalError={modalError}
        >
          <QuantitySelector
            key={productId}
            productId={productId}
            handleCtaClick={(quantity: number) =>
              handleCtaClick(quantity, node)
            }
            buttonArgs={{
              text: "Add to Basket"
            }}
            showSelector={false}
            isMobile={sm}
            itemPrice={getProductPriceForId(productId, true)}
            quantitySelectionChildren={
              <p>
                /user <br />
                /month (ex VAT)
              </p>
            }
            clickedMoreDetails={clickedMoreDetails}
          />
        </ExplainerTile>
      </Styled.ExplainerTileItemContainer>
    );
  };

  function renderList() {
    const productData = data.allContentfulExplainerTile.edges;
    return (
      productData
        // TODO: commented until ProductOffering API is available
        // .filter(filterContentById)
        .filter(
          (item) => item.node.serviceCategory.categoryId === selectedCategory
        )
        .map((element: ExplainerTileDataType) => renderItem(element.node))
    );
  }

  function renderProducts() {
    const productData = data.allContentfulExplainerTile.edges;
    return (
      productData
        // TODO: commented until ProductOffering API is available
        // .filter(filterContentById)
        .map((element: ExplainerTileDataType) =>
          element?.node?.productId === String(selectedProductId)
            ? renderItem(element.node)
            : null
        )
    );
  }

  return (
    <ThemeProvider theme={theme}>
      {!clickedMoreDetails ? (
        <>
          <Styled.ExplorePageContainerTop>
            <PageHeading
              headingText={data.contentfulPageHeading.heading}
              headingSubtext={data.contentfulPageHeading.headingSubtext}
              backgroundImgDark={
                data.contentfulPageHeading.headingBackgroundImageDark
              }
              formattedLocale={formattedLocale}
            />
          </Styled.ExplorePageContainerTop>
          <TabsContainer>
            <Tabs
              onTabClick={(categoryName) =>
                navigate(`/${formattedLocale}/cybershop/${categoryName}`)
              }
              externallySetTabId={selectedCategory}
              {...(theme.name === "WS10Dark" && {
                appearance: "secondary",
                inverse: true
              })}
            >
              {tabArray.map((selectedCategory) => (
                <Tab
                  text={selectedCategory.categoryName}
                  key={selectedCategory.categoryId}
                  id={selectedCategory.categoryId}
                />
              ))}
            </Tabs>
          </TabsContainer>
        </>
      ) : null}

      <Styled.ExploreHeadingBackground>
        <Styled.ExploreHeadingContainer>
          <Styled.HeadingTextWrapper>
            {!clickedMoreDetails ? (
              <>
                <Heading level={3} weight={1} text={tabHeading} noMargin />
                <Paragraph size={2} justify="left">
                  {tabDescription}
                </Paragraph>
              </>
            ) : (
              <Styled.BackButtonContainer>
                <LinkWithIcon
                  size={4}
                  appearance="secondary"
                  text="Back"
                  icon={{
                    name: "arrow-left",
                    justify: "left"
                  }}
                  onClick={() => {
                    navigate(0);
                  }}
                />
              </Styled.BackButtonContainer>
            )}
          </Styled.HeadingTextWrapper>
        </Styled.ExploreHeadingContainer>
      </Styled.ExploreHeadingBackground>

      <Styled.ExplorePageContainerBottom>
        <Styled.ExplainerTileContainer>
          {/*TODO: commented until ProductOffering API is available*/}
          {/*{!isLoading && (isError || !productListData?.length) ? (*/}
          {/*  <Styled.ErrorWrapper>*/}
          {/*    <StateNotification*/}
          {/*      state="error"*/}
          {/*      heading={{*/}
          {/*        text: "No products found"*/}
          {/*      }}*/}
          {/*    >*/}
          {/*      Please try again later*/}
          {/*    </StateNotification>*/}
          {/*  </Styled.ErrorWrapper>*/}
          {/*) : null}*/}

          {isLoading ? (
            <Styled.ExplainerTileItemContainer>
              <ServiceTileListSkeleton />
            </Styled.ExplainerTileItemContainer>
          ) : clickedMoreDetails && selectedProductId !== "0" ? (
            renderProducts()
          ) : (
            renderList()
          )}
        </Styled.ExplainerTileContainer>
      </Styled.ExplorePageContainerBottom>
    </ThemeProvider>
  );
};

export default ExplorePage;
