import styled, { css } from "styled-components";

export const ExplorePageContainerTop = styled.div`
  ${(props) => css`
    background-color: ${props.theme.name === "WS10"
      ? props.theme.color.monochrome1.default
      : props.theme.color.primary4.default};

    @media (max-width: ${props.theme.breakpoints.md}px) {
      & > div {
        background-color: ${props.theme.name === "WS10"
          ? props.theme.color.monochrome1.default
          : props.theme.inverseColor.monochrome1.default};
      }
    }
  `}
`;

export const ExplorePageContainerBottom = styled.div`
  ${(props) => css`
    background-color: ${props.theme.color.monochrome2.default};
    @media (min-width: ${props.theme.breakpoints.md}px) {
     & > div > div {
      background-color: ${
        props.theme.name === "WS10"
          ? props.theme.color.monochrome2.default
          : props.theme.inverseColor.monochrome1.default
      }
  `}
`;

export const ExplainerTileItemContainer = styled.div`
  ${(props) => css`
    background-color: ${props.theme.color.monochrome2.default};
    max-width: ${props.theme.gridConfig.springWidth}px;
    display: flex;
    justify-content: space-between;
    margin: auto;
    align-items: flex-end;
    min-height: 70px;
    padding-left: ${props.theme.spacing.fixed[5]}px;
    padding-right: ${props.theme.spacing.fixed[5]}px;
    padding-top: 35px;
    & svg {
      stroke: ${props.theme.color.monochrome6.default};
    }
    & > div > div:nth-child(1) div[class*="Interactionstyle"] {
      transition: all 0.3s;
      & :hover {
        background-color: ${props.theme.color.monochrome6.default};
        cursor: pointer;
      }
    }
  `}
`;

export const ExplainerTileContainer = styled.div`
  ${() => ` 
    padding-bottom: 35px;
  `}
`;

export const BackButtonContainer = styled.div`
  ${() => ` 
    padding-left: 6%;
  `}
`;

export const ExploreHeadingContainer = styled.div`
  background-size: auto;
  margin: auto;
  ${(props) => css` 
    max-width: ${props.theme.gridConfig.springWidth}px;
    padding: 0 ${props.theme.spacing.fixed[3]}px;
    };
  @media (max-width: ${props.theme.breakpoints.md - 1}px) {
      margin: 0 ;
      padding: 0;
    }
  `}
`;

export const ExploreHeadingBackground = styled.div`
  ${(props) => css` 
    background-color: ${
      props.theme.name === "WS10"
        ? props.theme.color.monochrome1.default
        : props.theme.color.monochrome2.default
    };
    @media (min-width: ${props.theme.breakpoints.md}px) {
      & > div > div {
        background-color: ${
          props.theme.name === "WS10"
            ? props.theme.color.monochrome1.default
            : props.theme.inverseColor.monochrome1.default
        }
    };
    };
    }
  `}
`;

export const HeadingTextWrapper = styled.div`
  ${(props) => css`
    @media (max-width: ${props.theme.breakpoints.md - 1}px) {
      padding-top: 12px;
      padding-bottom: 24px;
      & > h3 {
        line-height: 28px;
        font-size: 20px;
        font-weight: 700;
        padding-bottom: 2px;
        margin-inline: 16px;
      }
      & > p {
        letter-spacing: .5px;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        margin-inline: 16px;
      }
    }
    @media (min-width: ${props.theme.breakpoints.md}px) {
      padding-top: 48px;
      max-width: 450px;
      & > h3 {
        padding-bottom: 24px;
      }
      & > p {
        padding-bottom: 48px;
      }
    }
}`}
`;
export const ErrorWrapper = styled.div`
  margin: auto;
  max-width: ${(props) => props.theme.gridConfig.springWidth}px;
  padding: ${(props) => props.theme.spacing.fixed[8]}px
    ${(props) => props.theme.spacing.fixed[4]}px;
`;
