import { DefaultTheme } from "styled-components";

import { ExplainerTileThemeType } from "./ExplainerTile.theme.types";

const explainerTileLocal = (theme: DefaultTheme): ExplainerTileThemeType => {
  return {
    background: theme.color.monochrome1.default,
    iconBackground: theme.color.monochrome1.default
  };
};

export default explainerTileLocal;
