import styled, { css } from "styled-components";

export const PriceContainer = styled.div(
  () => css`
    display: flex;
    align-items: center;
    gap: 1em;
    height: 40px;
    & > h3:first-of-type {
      margin-bottom: 0;
    }
  `
);

export const QuantitySelectionWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  @media (max-width: 400px) {
    gap: 1.25em;
  }
`;

export const QuantitySelection = styled.div((props) => {
  return css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding-left: ${props.theme.spacing.fixed[4]}px;
    & > div:first-of-type {
      min-width: 200px;
      display: flex;
    }
    @media (max-width: 400px) {
      gap: 1.25em;
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      & > div:first-of-type {
        display: flex;
        justify-content: center;
      }
    }
  `;
});

export const ButtonMobile = styled.div`
  margin-top: 1em;
  width: 288px;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1.2em;
`;
