import styled, { css } from "styled-components";

import { elevation } from "@source-web/mixins";

import localTheme from "../themes/ExplainerTile.theme";
import { StyledExplainerTileProps } from "./ExplainerTile.style.types";

export const ExplainerTileWrapper = styled.div<StyledExplainerTileProps>(
  (props: StyledExplainerTileProps) => {
    const appearanceFromProp = localTheme(props.theme);
    return css`
      width: 100%;
      background-color: ${appearanceFromProp.background};
      ${elevation(true)}
      border-radius: ${props.theme.border.radius["2"]};
      display: flex;
      flex-direction: row;
      padding: ${props.theme.spacing.fixed[8]}px;

      @media (max-width: ${props.theme.breakpoints.lg}px) {
        padding: ${props.theme.spacing.fixed[8]}px
          ${props.theme.spacing.fixed[5]}px;
      }
      @media (max-width: ${props.theme.breakpoints.lg}px) {
        flex-direction: column;
        height: auto;
        padding: ${props.theme.spacing.fixed[4]}px;
      }
    `;
  }
);

// contains the first icon section
export const IconWrapper = styled.div<StyledExplainerTileProps>(
  (props: StyledExplainerTileProps) => {
    const appearanceFromProp = localTheme(props.theme);
    return css`
      display: flex;
      align-content: center;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      width: 175px;
      height: 175px;
      top: 100px;
      padding: ${props.theme.spacing.fixed[6]}px;
      background-color: ${appearanceFromProp.iconBackground};
      border-radius: ${props.theme.border.radius["2"]};
      ${elevation(true)};
      @media (max-width: ${props.theme.breakpoints.lg}px) {
        width: 100px;
        height: 100px;
        padding: ${props.theme.spacing.fixed[3]}px;
      }
    `;
  }
);

export const IconWrapperMobile = styled.div<StyledExplainerTileProps>(
  (props: StyledExplainerTileProps) => {
    const appearanceFromProp = localTheme(props.theme);
    return css`
      display: flex;
      align-content: center;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      width: 175px;
      height: 175px;
      top: 100px;
      padding: ${props.theme.spacing.fixed[6]}px;
      background-color: ${appearanceFromProp.iconBackground};
      border-radius: ${props.theme.border.radius["2"]};
      ${elevation(true)};
      @media (max-width: ${props.theme.breakpoints.lg}px) {
        width: 32px;
        height: 32px;
        padding: ${props.theme.spacing.fixed[2]}px;
      }
    `;
  }
);

export const ContentWrapper = styled.div`
  display: flex;
`;

export const DesktopLayout = styled.div(
  (props) => css`
    display: flex;
    width: 100%;
    flex-direction: column;
    @media (max-width: ${props.theme.breakpoints.lg}px) {
      display: none;
    }
  `
);

export const MobileLayout = styled.div(
  (props) => css`
    width: 100%;
    @media (min-width: ${props.theme.breakpoints.lg + 1}px) {
      display: none;
    }
    & > div:first-of-type {
      margin-bottom: 1.25em;
      display: flex;
      @media (max-width: ${props.theme.breakpoints.lg}px) {
        flex-direction: column;
      }
    }
  `
);

export const HeadingWrapper = styled.div`
  display: flex;
  margin-bottom: ${(props) => props.theme.spacing.fixed[4]}px;
  & > h2 {
    padding-left: ${(props) => props.theme.spacing.fixed[4]}px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    align-items: center;
    width: 100%;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    width: 100%;
  }
`;

// contains the middle section
export const DesktopCentralWrapper = styled.div(
  (props) =>
    css`
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 0 0 0 ${props.theme.spacing.fixed[8]}px;
    `
);

export const MoreDetailsWrapper = styled.div(
  () =>
    css`
      align-self: flex-end;
    `
);

export const TextWrapper = styled.div(
  (props) =>
    css`
      display: flex;
      width: 100%;
      height: 35%;

      & > div > p {
        width: auto;
      }
      @media (max-width: ${props.theme.breakpoints.lg}px) {
        flex-direction: column;
        & > div > p {
          width: auto;
          height: auto;
          padding-top: ${props.theme.spacing.fixed[4]}px;
        }
      }
    `
);

export const TextSpaceWrapper = styled.div(
  (props) =>
    css`
      padding-right: ${props.theme.spacing.fixed[8]}px;
    `
);

// contains the list section
export const ListWrapper = styled.div(
  (props) =>
    css`
      flex-shrink: 0;
      width: 50%;
      height: 100%;
      display: flex;
      flex-direction: column;

      @media (max-width: ${props.theme.breakpoints.lg}px) {
        margin-top: 1.25em;
        width: auto;
        padding: ${props.theme.spacing.fixed[0]};
      }
    `
);

//contains a list item (icon and text)
export const ListItemWrapper = styled.div(
  (props) =>
    css`
      width: 100%;
      padding-bottom: ${props.theme.spacing.fixed[3] - 4}px;
      & svg {
        fill: ${props.theme.color.primary1.default};
      }
    `
);

//contains the text and icon required for the link
export const LinkWrapper = styled.a(
  (props) => css`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 20px;
    text-decoration: none;
    margin-top: ${props.theme.spacing.fixed[7]}px;
    max-width: fit-content;
    & > span:last-child {
      padding-left: 12px;
    }
  `
);

export const MobileLinkWrapper = styled(LinkWrapper)(
  (props) => css`
    margin-top: ${props.theme.spacing.fixed[4]}px;
    margin-bottom: ${props.theme.spacing.fixed[4]}px;
    margin-left: auto;
  `
);

export const RequestCallbackContainer = styled.div`
  display: flex;
`;
