import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";

import { Checkbox } from "@source-web/checkbox";
import { Heading } from "@source-web/heading";
import { Paragraph } from "@source-web/paragraph";
import { SelectInputWithLabel } from "@source-web/select-input-with-label";
import { TextInputWithLabel } from "@source-web/text-input-with-label";

import { useAuth } from "../../context/auth/useAuth";
import {
  RequestCallbackType,
  SelectInputType
} from "../../pageTemplates/explore/queries/queries.types";
import {
  ButtonContainer,
  FieldWrapper,
  FormWrapper
} from "../../pageTemplates/support/components/SupportForm/styles/SupportForm.styles";
import { PrimaryButton } from "../PrimaryButton";
import { signInFormSchema } from "./requestCallbackFormSchema";
import * as Styled from "./styles/RequestCallback.styles";

export interface RequestCallbackValues {
  firstName: string;
  lastName: string;
  emailAddress: string;
  mobilePhone: string;
  company: string;
  postcode: string;
  businessSize: string;
  jobFunction: string;
  natureOfEnquiry: string;
  existingCustomer: string;
  emailoptin: boolean;
  customField: string;
}

interface RequestCallbackProps {
  onSubmit: (values: RequestCallbackValues) => void;
  data: RequestCallbackType;
  productId?: string;
  formattedLocale?: string;
}

interface FormikComponentProps {
  onSubmit: (values: RequestCallbackValues) => void;
  data: RequestCallbackType;
  user: any;
  initialNatureOfEnquiry: string;
  formattedLocale?: string;
  privacyPolicy: () => React.ReactElement;
}

const FormikComponent = ({
  onSubmit,
  data,
  initialNatureOfEnquiry,
  privacyPolicy,
  formattedLocale
}: FormikComponentProps) => {
  const { node } = data;

  return (
    <Formik
      key={initialNatureOfEnquiry}
      validateOnMount
      initialValues={{
        firstName: "",
        lastName: "",
        emailAddress: "",
        mobilePhone: "",
        company: "",
        postcode: "",
        businessSize: "",
        jobFunction: "",
        natureOfEnquiry: initialNatureOfEnquiry || "",
        existingCustomer: "Yes",
        emailoptin: false,
        customField: ""
      }}
      validationSchema={signInFormSchema}
      validate={(values) => {
        const errors: { [key: string]: string } = {};
        if (values.businessSize === "") {
          errors.businessSize = "Field required";
        }
        if (values.jobFunction === "") {
          errors.jobFunction = "Field required";
        }
        if (values.natureOfEnquiry === "") {
          errors.natureOfEnquiry = "Field required";
        }
        if (values.existingCustomer === "") {
          errors.existingCustomer = "Field required";
        }
        return errors;
      }}
      onSubmit={(values: RequestCallbackValues) => {
        const adaptedValues = {
          ...values,
          emailoptin: values.emailoptin ? "on" : "off"
        };

        onSubmit(adaptedValues as unknown as RequestCallbackValues);
      }}
    >
      {({
        handleChange,
        handleBlur,
        values,
        isSubmitting,
        isValid,
        touched,
        errors
      }) => {
        return (
          <Form>
            <FormWrapper>
              <TextInputWithLabel
                state={
                  touched.firstName && errors.firstName ? "error" : undefined
                }
                fieldWrapper={{
                  label: node.firstName,
                  required: true
                }}
                textInput={{
                  placeholder: `${node.firstNamePlaceholder}`,
                  id: "firstName",
                  name: "firstName",
                  onChange: handleChange,
                  value: values.firstName
                }}
              />
              <TextInputWithLabel
                state={
                  touched.lastName && errors.lastName ? "error" : undefined
                }
                fieldWrapper={{
                  label: node.lastName,
                  required: true
                }}
                textInput={{
                  placeholder: `${node.lastNamePlaceholder}`,
                  id: "lastName",
                  name: "lastName",
                  onChange: handleChange,
                  onBlur: handleBlur,
                  value: values.lastName
                }}
              />
              <TextInputWithLabel
                state={
                  touched.emailAddress && errors.emailAddress
                    ? "error"
                    : undefined
                }
                fieldWrapper={{
                  label: node.yourEmailAddress,
                  required: true
                }}
                textInput={{
                  placeholder: `${node.yourEmailAddressPlaceholder}`,
                  id: "emailAddress",
                  name: "emailAddress",
                  onChange: handleChange,
                  value: values.emailAddress
                }}
              />
              <TextInputWithLabel
                state={
                  touched.mobilePhone && errors.mobilePhone
                    ? "error"
                    : undefined
                }
                fieldWrapper={{
                  label: node.contactNumber,
                  required: true
                }}
                textInput={{
                  placeholder: `${node.contactNumberPlaceholder}`,
                  id: "mobilePhone",
                  name: "mobilePhone",
                  onChange: handleChange,
                  value: values.mobilePhone
                }}
              />
              <TextInputWithLabel
                state={touched.company && errors.company ? "error" : undefined}
                fieldWrapper={{
                  label: node.companyName,
                  required: true
                }}
                textInput={{
                  placeholder: `${node.companyNamePlaceholder}`,
                  id: "company",
                  name: "company",
                  onChange: handleChange,
                  value: values.company
                }}
              />
              <TextInputWithLabel
                state={
                  touched.postcode && errors.postcode ? "error" : undefined
                }
                fieldWrapper={{
                  label: node.postcode,
                  required: true
                }}
                textInput={{
                  placeholder: `${node.postcodePlaceholder}`,
                  id: "postcode",
                  name: "postcode",
                  onChange: handleChange,
                  value: values.postcode
                }}
              />

              <FieldWrapper>
                <SelectInputWithLabel
                  state={
                    touched.businessSize && errors.businessSize
                      ? "error"
                      : undefined
                  }
                  fieldWrapper={{
                    label: `${node.businessSizeLabel}`,
                    required: true
                  }}
                  selectInput={{
                    id: "businessSize",
                    name: "businessSize",
                    onChange: handleChange,
                    value: values.businessSize,
                    options:
                      node.childrenContentfulRequestCallbackDropdownJsonNode[0]
                        .businessSize
                  }}
                />
              </FieldWrapper>

              <FieldWrapper>
                <SelectInputWithLabel
                  state={
                    touched.jobFunction && errors.jobFunction
                      ? "error"
                      : undefined
                  }
                  fieldWrapper={{
                    label: `${node.jobFunctionLabel}`,
                    required: true
                  }}
                  selectInput={{
                    id: "jobFunction",
                    name: "jobFunction",
                    onChange: handleChange,
                    value: values.jobFunction,
                    options:
                      node.childrenContentfulRequestCallbackDropdownJsonNode[0]
                        .jobFunction
                  }}
                />
              </FieldWrapper>
              <FieldWrapper>
                <SelectInputWithLabel
                  state={
                    touched.natureOfEnquiry && errors.natureOfEnquiry
                      ? "error"
                      : undefined
                  }
                  fieldWrapper={{
                    label: `${node.natureOfEnquiryLabel}`,
                    required: true
                  }}
                  selectInput={{
                    id: "natureOfEnquiry",
                    name: "natureOfEnquiry",
                    onChange: handleChange,
                    value: values.natureOfEnquiry,
                    options:
                      formattedLocale === "it"
                        ? node.childrenContentfulRequestCallbackDropdownJsonNode[0].natureOfEnquiry.filter(
                            (item) => item.productId !== "115620"
                          )
                        : node
                            .childrenContentfulRequestCallbackDropdownJsonNode[0]
                            .natureOfEnquiry
                  }}
                />
              </FieldWrapper>
              {node.showExistingCustomerLabel && (
                <FieldWrapper>
                  <SelectInputWithLabel
                    state={
                      touched.existingCustomer && errors.existingCustomer
                        ? "error"
                        : undefined
                    }
                    fieldWrapper={{
                      label: `${node.existingCustomerLabel}`,
                      required: true
                    }}
                    selectInput={{
                      id: "existingCustomer",
                      name: "existingCustomer",
                      onChange: handleChange,
                      value: values.existingCustomer,
                      options:
                        node
                          .childrenContentfulRequestCallbackDropdownJsonNode[0]
                          .existingCustomer
                    }}
                  />
                </FieldWrapper>
              )}
              {node.showCustomField && (
                <TextInputWithLabel
                  state={
                    touched.customField && errors.customField
                      ? "error"
                      : undefined
                  }
                  fieldWrapper={{
                    label: node.customField,
                    required: true
                  }}
                  textInput={{
                    placeholder: node.customField,
                    id: "customField",
                    name: "customField",
                    onChange: handleChange,
                    value: values.customField
                  }}
                />
              )}
            </FormWrapper>

            <Styled.CheckboxContainer>
              <Checkbox
                value="emailoptin"
                id="emailoptin"
                name="emailoptin"
                checked={values.emailoptin}
                onChange={handleChange}
              >
                <Paragraph size={1}>{node.checkboxText}</Paragraph>
              </Checkbox>
            </Styled.CheckboxContainer>
            <Styled.ParagraphContainer>
              <Paragraph size={2}>{privacyPolicy()}</Paragraph>
            </Styled.ParagraphContainer>
            <ButtonContainer>
              <PrimaryButton
                text={node.submitButtonText}
                loading={isSubmitting}
                state={!isValid ? "disabled" : undefined}
                htmlAttributes={{
                  type: "submit"
                }}
                onClick={() => {}}
              />
            </ButtonContainer>
          </Form>
        );
      }}
    </Formik>
  );
};

export default function RequestCallback({
  onSubmit,
  data,
  productId,
  formattedLocale
}: RequestCallbackProps) {
  const { user } = useAuth().authState;
  const [initialNatureOfEnquiry, setInitialNatureOfEnquiry] = useState("");

  const mapProductIdToNatureOfEnquiry = (
    productId: string | undefined,
    natureOfEnquiryOptions: SelectInputType[]
  ) => {
    const option = natureOfEnquiryOptions.find(
      (item) => item.productId === productId
    );
    return option ? option.value : "";
  };

  useEffect(() => {
    const natureOfEnquiryOptions =
      data.node.childrenContentfulRequestCallbackDropdownJsonNode[0]
        .natureOfEnquiry;
    const preSelectedValue = mapProductIdToNatureOfEnquiry(
      productId,
      natureOfEnquiryOptions
    );
    setInitialNatureOfEnquiry(preSelectedValue);
  }, [productId, data]);

  const { node } = data;

  const privacyPolicy = () => {
    const [before, after] = node.privacyPolicy.split("{privacyPolicyLabel}");
    return (
      <>
        {`${before}`}
        <a href={node.privacyPolicyLink} target="_blank" rel="noreferrer">
          {node.privacyPolicyLabel}
        </a>
        {`${after}`}
      </>
    );
  };

  return (
    <Styled.MainContainer>
      <Styled.HeadingContainer>
        <Heading size={2} text={node.title} />
      </Styled.HeadingContainer>
      <FormikComponent
        onSubmit={onSubmit}
        data={data}
        initialNatureOfEnquiry={initialNatureOfEnquiry}
        user={user}
        privacyPolicy={privacyPolicy}
        formattedLocale={formattedLocale}
      />
    </Styled.MainContainer>
  );
}
